/* purgecss start ignore */
@import "tailwindcss/base";
@import "tailwindcss/components";
/* purgecss end ignore */

/* Write your styles below this line. */

// @import "react-datetime/css/react-datetime.css";
// @import "flatpickr/dist/flatpickr.min.css";
@import 'flatpickr/dist/flatpickr.min.css';

@import "./components/checkbox.scss";
@import './components/_form.scss';
@import "./components/_badge.scss";
@import "./components/_notice.scss";
@import "./components/_panel.scss";
@import "./components/_dropdown.scss";
@import "./components/_modal.scss";
@import "./components/_table.scss";
@import "./components/_tooltip.scss";
@import "./components/_helper.scss";
@import "./components/_processing_bar.scss";
@import "./components/_time_countdown.scss";
@import "./components/_editor_draft.scss";
@import "./components/flatpickr_airbnb";
@import "./components/alert";

@import './pages/common.scss';
@import './pages/layout.scss';
@import './pages/market_lease.scss';
@import './pages/market_auction.scss';
@import './pages/print.scss';
@import './pages/account.scss';
@import './pages/deposit.scss';
@import './pages/payments_method.scss';
@import "react-slidedown/lib/slidedown.css";
@import 'react-tiga-swiper/dist/index.css';
@import 'rc-slider/assets/index.css';
@import "./components/slider.scss";

/* Write your styles below this line. */

@import "tailwindcss/utilities";
@import "tailwindcss/screens";
